.action-bar {
  position: sticky;
  display: flex;
  width: 100%;
  gap: 1rem;
  top: -1px;
  z-index: 1000;
  width: auto;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
}
h3 + .action-bar:first-of-type {
  border-bottom: 1px solid #ccc;
  top: 0;
}