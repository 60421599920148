.segment-list {
  .list__title {
    height: auto;
    margin-top: 0.75rem;

    &:first-child {
      margin-top: 0;
    }
    h6 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
}
