$greyColor: #737373;

.sidebar {
  width: 200px;
  height: 100vh;
  left: 0;

  .logo {
    width: 100%;
  }

  a {
    color: $greyColor;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.5rem;
    min-height: 2.5rem;
  
    &:link,
    &:visited,
    &:active,
    &:hover {
      text-decoration: none;
      color: $greyColor;
    }
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
  
    span {
      padding: 0 0.5rem;
    }
  }

  .title {
    height: 2.5rem;
    box-sizing: content-box;
    margin-bottom: 0.65rem;
    overflow: hidden;
    a {
      color: inherit;
    }
  }

  .tag-icon {
    transform: rotate(-45deg);
  }

}
