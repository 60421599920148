$minHeight: 40px;
$grayColor: rgba(0, 0, 0, 0.08);
$padding: 0.75rem;

.card-list {
  margin-bottom: $padding;
  > ul {
    margin-bottom: 0;
  }
}

.card-list,
.card-list > ul {
  display: flex;
  flex-direction: column;
  gap: $padding;
}

.card {
  position: relative;  
  border-bottom: 1px solid rgba($grayColor, .5);
  .form-group {
    margin: 0;
  }
  &.card-type-textfield,
  &.card-type-button {
    border-bottom: 0px !important;
    .card-body {
      border: none
    }
  }
}

.card-header {
  position: relative;
  min-height: $minHeight;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: $grayColor;
  > *:first-child {
    margin-left: $padding;
  }
  > *:last-child {
    margin-right: $padding;
  }
  .card-title {
    text-align: left;
    flex-grow: 1;
    .form-group { 
      > div {
        height: $minHeight;
        margin: -1px;
        z-index: 1;
        margin-left: $padding * -1;
      }
    }
    input {
      border: none;
      background-color: transparent;
    }
  }

  .card-prefix:empty + .card-title {
    margin-left: $padding * -1;
    input, span {
      padding-left: $padding;
    }
  }
  
  .card-prefix {
    > * {
      margin-right: $padding;
    }
    .hex {
      border: 1px solid $grayColor;
      width: $padding;
      height: $padding;
    }
  }
  .card-actions {
    text-align: right;
  }
}

.card-body {
  position: relative;
  padding: $padding;
  border: 1px solid rgba($grayColor, .5);
  border-bottom: 0;
  > button:first-child {
    outline: none;
    border: 1px solid $grayColor !important;
    background: $grayColor !important;
    &:hover {
      background: rgba($grayColor, .1) !important;
    }
    width: 100%;
    height: 100%;
  }
  &:first-child {
    height: 100%;
  }
  textarea {
    resize: none;
  }
}

.card-footer {
  position: relative;
  padding: $padding;
  border: 1px solid rgba($grayColor, .5);
  border-top: 0;
  border-bottom: 0;
}

.card-no-padding {
  .card-body,
  .card-footer {
    padding: 0;
  }
  .card-header {
    > *:last-child {
      margin-right: 0;
    }
  }
}