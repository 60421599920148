$advanced-filter-border: #999999;

/* list */
.advanced-filter-section {
  border: 1px solid $advanced-filter-border;
  position: relative;

  &:before {
    background: #ffffff;
    border-right: 1px solid $advanced-filter-border;
    border-top: 1px solid $advanced-filter-border;
    content: '';
    height: 20px;
    left: 20px;
    position: absolute;
    top: -10px;
    transform: rotate(-45deg);
    width: 20px;
  }
}

/* item */
.title {
  color: #000000;
}

.description {
  .empty {
    font-style: italic;
  }
}

.metadata {
  display: inline-flex;
  font-family: 'yarn-monospace', 'Lucida Console', Monaco, monospace;
  font-size: 0.9em;
  color: #999999;
  height: 22px;

  & > * {
    margin-right: 2rem;
  }

  .tags {
    align-items: flex-end;
    display: inline-flex;

    .tag-icon {
      margin-right: 0.5rem;
      transform: rotate(-45deg);
    }
  }
}

.type {
  font-family: 'yarn-monospace', 'Lucida Console', Monaco, monospace;
}

.type-icon {
  border: 1px solid;
  font-weight: bold;
  width: 2rem;
  line-height: 1rem;
  text-align: center;
  font-size: 0.6rem;
}

.flag-status-switch {
  margin-right: 0.25rem;
  margin-top: 0;
}

/* history */
.flag-history {
	min-width: 60vw;
	height: 60vh;
	display: flex;
	flex-direction: column;
}

$diffLineHeight: 1.4rem;
$diffBorderColor: rgba(0,0,0,.1);
.diff {
	table-layout: fixed;
	margin-bottom: 1px;
	overflow: hidden;
	position: relative;
	&__key {
		padding: .5rem 0;
		font-weight: 700;
		position: sticky;
    top: -1px;
    left: 0;
    background: white;
    z-index: 1;
	}
	pre {
		background: none;
		border: none;
		margin: 0;
		line-height: $diffLineHeight;
		color: rgba(0,0,0,.85);
	}
	td {
		padding-top: 0;
		padding-bottom: 0;
		line-height: $diffLineHeight;
		border-left: 1px solid $diffBorderColor;
	}
	td:first-child {
		border-left: 0;
	}
	&__line {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: $diffLineHeight;
			border-bottom: 1px solid $diffBorderColor;
		}
		&:last-child {
			&:after {
				border-bottom-color: transparent;
			}
		}
		&:hover {
			&:after {
				background-color: $diffBorderColor;
			}
		}
	}
}

.diff-list {
	overflow-y: auto;
}

.same {
	background: rgb(245, 245, 245);
}

.removed {
	background: rgb(255, 212, 219);
}

.added {
	background: rgb(141, 255, 141);
}

/* actions */
.flag-copy-selectors {
	width: 60vw;
	max-width: 768px;
}