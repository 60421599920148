.list {
  position: relative;
  .item {
    position: relative;
    &_veiled > * {
      &:hover {
        user-select: none;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        cursor: move;
        opacity: 0.9;
      }
    }
    &_dragging {
      opacity: 0.6;
      outline: 2px solid #000;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
      z-index: 1001;
    }
  }
}

.toggler {
  cursor: pointer;
}

.handler {
  cursor: move;
  &.hiding {
    display: none !important;
  }
}
