@use "sass:math";

$margin: 2rem;
.notify {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  &_list {
    position: absolute;
    bottom: $margin;
    right: $margin;
    width: 300px;
  }
  &_item {
    margin-top: math.div($margin, 4);
  }
}
