@import '~yarn-design-system/dist/yarn-base.css';
@import '~yarn-design-system/dist/yarn-print.css';
@import '~yarn-design-system/dist/yarn-utils.css';
@import '~yarn-design-system/dist/yarn-grid.css';
@import '~yarn-design-system/dist/yarn-core.css';
@import '~yarn-design-system-icons/dist/yarn-icons.css';
@import '~yarn-design-system-logos/dist/yarn-logos.css';
@import '~yarn-design-system-fonts/dist/yarn-fonts.css';
@import '~yarn-design-system-react-select/dist/react-select.css';

:root {
  --header-height: 3rem;
  --container-padding: 1rem;
  --main-height: calc(100vh - var(--header-height))
}

/* responsive base  */
*,
*:after,
*:before {
  box-sizing: border-box;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#root > header {
  position: static;
}
main {
  display: flex;
  flex-direction: row;
  height: var(--main-height);
}
aside + section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: var(--main-height);
}
.container-x-padding {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}
.negative-margin {
  margin-left: calc(var(--container-padding) * -1);
  margin-right: calc(var(--container-padding) * -1);
}

/* flex */
.flex {
  width: 100%;
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-expand,
.flex-1 {
  flex: 1;
}
.shrink-0 {
  flex-shrink: 0;
}
.shrink-1 {
  flex-shrink: 1;
}
.flex-align-center,
.items-center {
  align-items: center !important;
}
.flex-justify-center,
.justify-center {
  justify-content: center !important;
}
.flex-justify-between {
  justify-content: space-between !important;
}
.self-start {
  align-self: self-start;
}

/* grid */
.grid {
  display: grid;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}
.col-span-2 {
  grid-column: span 2/span 2;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}

aside + .container.fill,
aside + section,
#modal + .container.fill,
#modal + section  {
  overflow-y: scroll;
}

/* react select */
.react-select {
  height: 100%;
}
.react-select .react-select__control {
  min-height: 2.5rem;
}
.react-select .react-select__value-container {
  flex-wrap: wrap;
}
.react-select .react-select__multi-value {
  margin: 0.2rem 0.2rem 0.2rem 0;
}
.react-select .react-select__multi-value__label {
  display: inline;
  text-overflow: ellipsis;
}

/* typography improvements */
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-all;
}
a {
  color: #000;
  text-decoration: underline;
}
a:hover {
  color: #000;
  text-decoration: none;
}
ul > li,
ol > li {
  padding-left: 0;
  padding-right: 0;
}
small {
  font-size: .875rem;
}
.section-details-divider {
  margin: 1.5rem auto;
}

/* table */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.table-condensed tr {
  margin: 0;
  height: auto;
}
.table-condensed td {
  line-height: normal;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
.table-hover tbody tr:hover td {
  background-color: rgba(0, 0, 0, 0.05);
}

/* additional utilities for yarn-design-system ui */
.fill {
  min-height: 100%;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.m-auto {
  margin: auto !important;
}
.h-full {
  height: 100% !important;
}
.w-full {
  width: 100% !important;
}
.w-half {
  width: 50% !important;
}
.w-auto {
  width: auto !important;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-10 {
  z-index: 10;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.border-0 {
  border: 0 !important;
}
.border-none {
  border: none !important;
}
.form-groups-no-margin .form-group {
  margin: 0;
}
