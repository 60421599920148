$headerHeight: 2.5rem;
$padding: 0.75rem;
$grayColor: rgba(0, 0, 0, 0.08);

@mixin btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: inherit;
}

.split {
  display: inline-block;
  position: relative;
  border-left: 1px solid #737373;
  margin-left: 2rem;

  table {
    width: auto;
  }
  table,
  td,
  tr {
    border: 0;
    margin: 0;
    height: auto;
    padding-top: 0 !important;
  }
  tr:last-child  {
    td {
      padding-bottom: 0 !important;
    }
  }
  &::before {
    content: '';
    position: absolute;
    border-bottom: 1px solid #737373;
    height: 0px;
    width: 2rem;
    left: -2rem;
    top: 1.2rem;
  }
  * > div > div {
    position: relative;
    &::before {
      content: '%';
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }
  }
}
