.modal-accessability {
  position: absolute;
  ul {
    li {
      list-style: square inside;
    }
  }
  [open] {
    min-width: 320px;
  }
}
.modal-title {
  font-family: 'yarn-base';
  word-break: normal;
  line-height: normal;
}